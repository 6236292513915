import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import Loader from "components/Loader";
import RegisterName from "components/RegisterName";
import ConfirmEmail from "components/RegisterName/ConfirmEmail";
import BannerHead from "components/Banners";
import { useAuth0 } from "@auth0/auth0-react";

export default function App() {
  const [controller] = useMaterialUIController();
  const { direction, darkMode } = controller;
  const [rtlCache, setRtlCache] = useState(null);
  const [Status, setStatus] = useState(false);
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth0();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <Header setStatus={setStatus} />
      <Routes>{getRoutes(routes)}</Routes>
      {Status === 200 && <BannerHead />}
      {Status === 200 && <Routes>{getRoutes(routes)}</Routes>}
      {Status === 404 && <RegisterName />}
      {Status === 401 && <ConfirmEmail />}
      {!Status && isAuthenticated && <Loader />}
      {!isAuthenticated && <Routes>{getRoutes(routes)}</Routes>}
      <Footer />
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <Header setStatus={setStatus} />
      {Status === 200 && <BannerHead />}
      {Status === 200 && <Routes>{getRoutes(routes)}</Routes>}
      {Status === 404 && <RegisterName />}
      {Status === 401 && <ConfirmEmail />}
      {!Status && isAuthenticated && <Loader />}
      {!isAuthenticated && <Routes>{getRoutes(routes)}</Routes>}
      <Footer />
    </ThemeProvider>
  );
}
