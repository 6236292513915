import { withAuthenticationRequired } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import Loader from "components/Loader";
import React from "react";

function RoutePrivate({ Component }) {
  const AuthComponent = React.useMemo(
    () =>
      withAuthenticationRequired(Component, {
        onRedirecting: Loader,
      }),
    [Component]
  );
  return <AuthComponent />;
}

RoutePrivate.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

export default RoutePrivate;
