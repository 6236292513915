import React, { createContext, useState, useMemo } from "react";

export const dataUserContext = createContext();

function ContextUser({ ...rest }) {
  const [userData, setUserData] = useState(false);
  const data = useMemo(() => ({ setUserData, userData }), [userData]);
  return <dataUserContext.Provider value={data}>{rest.children}</dataUserContext.Provider>;
}

export default ContextUser;
