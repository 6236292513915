import { useContext } from "react";
import { dataUserContext } from "context/ContextUser";
import { FormattedMessage } from "react-intl";
import LogoCalendar from "assets/images/icons/flags/calendario.svg";

function BannerHead() {
  const { userData } = useContext(dataUserContext);
  return (
    <div className="container flex">
      <div>
        <div className="flex aling-item-center mt-4">
          <img src={LogoCalendar && LogoCalendar} alt="logo calendario" width={40} />
          <h1 className="title-banner">
            <FormattedMessage id="Banner-head.tittle" />
          </h1>
        </div>
        <h2 className="subtitle-banner">{userData && userData.name}</h2>
      </div>

      <div className="logo-banner">
        {userData && <img src={userData.client.logo} alt="logo del usuario" width={300} />}
      </div>
    </div>
  );
}

export default BannerHead;
