import { Card, Grid } from "@mui/material";
import InputCalendar from "components/InputCalendar";
import MDBox from "components/MDBox";
import React from "react";

function ViewCreateSchedule() {
  return (
    <MDBox pt={4} className="container">
      <Grid item xs={3} xl={12} sx={{ height: "max-content" }}>
        <Card sx={{ height: "95%" }} className="-my-1">
          <InputCalendar />
        </Card>
      </Grid>
    </MDBox>
  );
}

export default ViewCreateSchedule;
