import Loader from "components/Loader";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import ViewCalendar from "./components/NextEvents/ViewCalendar";

function Calendar() {
  return <ViewCalendar />;
}
export default withAuthenticationRequired(Calendar, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <Loader />,
});
