import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import UpdateCalendar from "components/UpdateCalendar";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function ViewUpdatedSchedule() {
  const { id } = useParams();
  const [dataUpdated, setDataUpdated] = useState(false);

  useEffect(() => {
    if (!dataUpdated) {
      const data = JSON.parse(localStorage.getItem("schedules"));
      const search = data.find((schedule) => id === schedule.id);
      setDataUpdated(search);
    }
  }, [dataUpdated]);

  return (
    <MDBox pt={4} className="container">
      {dataUpdated && (
        <Grid item xs={3} xl={12} sx={{ height: "max-content" }}>
          <Card sx={{ height: "95%" }} className="-my-1">
            <UpdateCalendar dataUpdated={dataUpdated} />
          </Card>
        </Grid>
      )}
    </MDBox>
  );
}

export default ViewUpdatedSchedule;
