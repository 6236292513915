import React from "react";
import { FormattedMessage } from "react-intl";

function ConfirmEmail() {
  return (
    <div className="bg-white card-login rounded text-center">
      <h2>
        <FormattedMessage id="register.titleEmailConfirm" />
      </h2>
      <button
        type="submit"
        className="mt-4 text-white font-bold py-2 px-4 w-full rounded active"
        onClick={() => {
          window.location.href = "";
        }}
      >
        <FormattedMessage id="register.btn-confirmEmail" />
      </button>
    </div>
  );
}

export default ConfirmEmail;
