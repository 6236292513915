import PropTypes from "prop-types";
import "./Calendar.css";
import { CSVLink } from "react-csv";
import ReactDOM from "react-dom";
import useRequests from "Hooks/useRequests";
// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useContext, useState } from "react";
import { langContext, LangProvider } from "context/Lang";
import { FormattedMessage, useIntl } from "react-intl";
import Swal from "sweetalert2";
import { Auth0Provider } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import Already from "./Already";

function Calendar({ header, ...rest }) {
  const [controller] = useMaterialUIController();
  const { getWithTokenAuth0 } = useRequests();
  const { locale } = useContext(langContext);
  const { darkMode } = controller;
  const [send, setSend] = useState(false);
  const intl = useIntl();
  const validClassNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "custom",
    "custom-success",
  ];

  async function handleClickEvent(event, e) {
    const getData = await getWithTokenAuth0(
      `${process.env.REACT_APP_URL_API_MNG}/schedules/${e.event.id}/activity`
    );
    const getData2 = await getWithTokenAuth0(
      `${process.env.REACT_APP_URL_API_MNG}/schedules/${e.event.id}/recording`
    );

    const csvData = [...getData.result];

    const csvHeaders = [
      {
        key: "screen",
        label: "Screen",
      },
      {
        label: "Attendee",
        key: "attendee",
      },
      {
        key: "action",
        label: "Action",
      },
      {
        key: "date",
        label: "Date",
      },
    ];

    const csvFilename = "attendes.csv";

    const csvReport = {
      data: csvData,
      headers: csvHeaders,
      filename: csvFilename,
    };

    const content = (
      <div className="popup-content">
        <div className="head-popup">{event.human_date}</div>
        <div className="head-popup">
          {event.hours} {event.minutes} min
        </div>
        <div className="mt-4">
          <p>
            <b>{intl.formatMessage({ id: "inputCalendar.inputTitle" })}</b>
            <br />
            <span>{event.name}</span>
          </p>
        </div>
        <div>
          <p>
            <b>{intl.formatMessage({ id: "inputCalendar.inputGuests" })}</b>
            <br />
            {event.guests.map((element) => (
              <span key={`${element.name}-${new Date().getTime()}`}>
                {element.name.replace(",", "")}
              </span>
            ))}
          </p>
        </div>
        <div>
          <p>
            <b>{intl.formatMessage({ id: "inputCalendar.inputComments" })}</b>
            <br />
            {event.comments}
          </p>
        </div>
        <div className="mt-4">
          <p>
            <b>{intl.formatMessage({ id: "inputCalendar.recordings" })}</b>
            <br />
            <div className="recordings">
              {getData2.result.map((element) => (
                <a
                  className="text-recording"
                  target="_blank"
                  rel="noreferrer"
                  href={element.file}
                  key={element.number}
                >
                  {intl.formatMessage({ id: "inputCalendar.recording" })} {element.number}
                </a>
              ))}
            </div>
          </p>
        </div>
      </div>
    );

    const buttons = (
      <div className="popup-buttons">
        <CSVLink {...csvReport} className="confirm-button">
          {intl.formatMessage({ id: "buttons.activity" })}
        </CSVLink>
        <button id="confirm-button" className="confirm-button" type="submit">
          {intl.formatMessage({ id: "buttons.confirm" })}
        </button>
      </div>
    );

    Swal.fire({
      title: intl.formatMessage({ id: "inputCalendar.info" }),
      html: `<div class=".swal2-html-container"></div>`,
      showDenyButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      customClass: "alert-popup",
      didOpen: (popup) => {
        const htmlContainer = popup.querySelector(".swal2-html-container");
        ReactDOM.render(
          <div>
            {content}
            {buttons}
          </div>,
          htmlContainer
        );
        document.getElementById("confirm-button").addEventListener("click", () => {
          Swal.close(); // Cierra el popup al hacer clic en el botón "Confirm"
        });
      },
    });
  }

  function handleButtonReady() {
    Swal.fire({
      title: intl.formatMessage({ id: "inputCalendar.intanAppoinment" }),
      showConfirmButton: false,
      html: '<div id="my-component" class="popup-content"></div>',
      showCloseButton: true,
      didOpen: () => {
        ReactDOM.render(
          <Auth0Provider
            domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
            clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
            redirectUri={window.location.origin}
            audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
            scope="read:current_user update:current_user_metadata"
          >
            <LangProvider>
              <Already send={send} />
            </LangProvider>
          </Auth0Provider>,
          document.getElementById("my-component")
        );
      },
      willClose: () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("my-component"));
      },
      customClass: "alert-popup",
    }).then(() => setSend(true));
  }

  const events = rest.events
    ? rest.events.map((el) => ({
        ...el,
        className: validClassNames.find((item) => item === el.className)
          ? `event-${el.className}`
          : "event-info",
      }))
    : [];

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={header.title || header.date ? 2 : 0} px={2} lineHeight={1}>
        {header.title ? (
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {header.title}
          </MDTypography>
        ) : null}
        {header.date ? (
          <MDTypography component="p" variant="button" color="text" fontWeight="regular">
            {header.date}
          </MDTypography>
        ) : null}
      </MDBox>
      <CalendarRoot p={2} ownerState={{ darkMode }}>
        <FullCalendar
          locale={locale === "es-MX" ? "es" : ""}
          {...rest}
          customButtons={{
            buttonReady: {
              text: "¡Ya!",
              click: () => handleButtonReady(),
            },
            myCustomButton: {
              text: (
                <Link to="/create-schedule">
                  <FormattedMessage id="buttons.create" />
                </Link>
              ),
            },
          }}
          headerToolbar={{
            start: "title", // will normally be on the left. if RTL, will be on the right
            end: `myCustomButton buttonReady today prev,next`, // will normally be on the right. if RTL, will be on the left
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          height="100%"
          eventClick={(e) => {
            handleClickEvent(e.event.extendedProps, e);
          }}
        />
      </CalendarRoot>
    </Card>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
