import React, { useState } from "react";
import PropTypes from "prop-types";
import Hours from "components/InputCalendar/Hours";
import "./styles.css";

function DropdownHours({ setTime, setMinute, timeInit12, timeInit24, timeEnd12, selectedDay }) {
  const [isOpen, setIsOpen] = useState({});

  function handleClickIsOpen(dropDown) {
    setIsOpen({ ...isOpen, [dropDown]: !isOpen[dropDown] });
  }

  function handleClickItem(item, value, dropDown) {
    if (dropDown === "dropDownOne") {
      setTime((prevTime) => ({ ...prevTime, hourInit: value, hourEnd: value }));
    } else {
      setTime((prevTime) => ({ ...prevTime, [item]: value }));
    }
    handleClickIsOpen(dropDown);
  }

  return (
    <div className="drop-down-hours">
      <div className="drop-down">
        <div className="drop-down-select">
          <div className="text">{timeInit12 !== "" ? timeInit12 : "12:00 am"}</div>{" "}
          <button
            className="drop-icon"
            type="submit"
            onClick={() => handleClickIsOpen("dropDownOne")}
          >
            v
          </button>
        </div>
        {isOpen.dropDownOne && (
          <div className="drop-down-content">
            {Hours &&
              Hours.map(
                (hour) =>
                  new Date(
                    new Date(
                      new Date(selectedDay).setMinutes(parseInt(hour.hour_24.split(":")[1], 10))
                    ).setHours(parseInt(hour.hour_24.split(":")[0], 10))
                  ).getTime() > new Date().getTime() && (
                    <button
                      key={hour.hour_24}
                      type="submit"
                      className="drop-down-item"
                      onClick={() => handleClickItem("hourInit", hour, "dropDownOne")}
                    >
                      {hour.hour_12}
                    </button>
                  )
              )}
          </div>
        )}
      </div>
      <div className="drop-down mleft-15">
        <div className={timeInit12 !== "" ? "drop-down-select" : "drop-down-select disabled"}>
          <div className="text">{timeEnd12 !== "" ? timeEnd12 : "01:00 pm"}</div>{" "}
          <button
            className="drop-icon"
            type="submit"
            onClick={() => timeInit12 !== "" && handleClickIsOpen("dropDownTwo")}
          >
            v
          </button>
        </div>
        {isOpen.dropDownTwo && (
          <div className="drop-down-content width100">
            {Hours &&
              Hours.map((hour) => {
                const init = new Date(`01/01/2000 ${timeInit24}`);
                const end = new Date(`01/01/2000 ${hour.hour_24}`);

                const diferent = end.getTime() - init.getTime();
                const minutesCalculates = Math.floor(diferent / (1000 * 60));

                if (
                  new Date(
                    new Date(
                      new Date(selectedDay).setMinutes(parseInt(hour.hour_24.split(":")[1], 10))
                    ).setHours(parseInt(hour.hour_24.split(":")[0], 10))
                  ).getTime() >
                  new Date(
                    new Date(
                      new Date(selectedDay).setMinutes(parseInt(timeInit24.split(":")[1], 10))
                    ).setHours(parseInt(timeInit24.split(":")[0], 10))
                  ).getTime()
                ) {
                  return (
                    <button
                      key={hour.hour_24}
                      type="submit"
                      className="drop-down-item"
                      onClick={() => {
                        handleClickItem("hourEnd", hour, "dropDownTwo");
                        setMinute(minutesCalculates);
                      }}
                    >
                      {hour.hour_12} (
                      {minutesCalculates <= 60
                        ? `${minutesCalculates} m`
                        : `${minutesCalculates / 60} h`}{" "}
                      )
                    </button>
                  );
                }
                return null;
              })}
          </div>
        )}
      </div>
    </div>
  );
}

DropdownHours.defaultProps = {
  setTime: "",
  setMinute: "",
  timeInit12: "",
  timeInit24: "",
  timeEnd12: "",
  selectedDay: new Date(),
};

// Typechecking props for the Calendar
DropdownHours.propTypes = {
  timeInit12: PropTypes.string,
  timeInit24: PropTypes.string,
  timeEnd12: PropTypes.string,
  selectedDay: PropTypes.instanceOf(Date),
  setTime: PropTypes.func,
  setMinute: PropTypes.func,
};

export default DropdownHours;
