import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import DropdownHours from "components/DropdownHours";
import { langContext } from "context/Lang";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameMonth,
  isToday,
  parse,
} from "date-fns";
import { enAU, es } from "date-fns/locale";
import useRequests from "Hooks/useRequests";
import React, { useContext, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const colStartClasses = [
  "",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

function UpdateCalendar({ ...rest }) {
  const { putWithTokenAuth0 } = useRequests();
  const navigate = useNavigate();
  const { locale } = useContext(langContext);
  const intl = useIntl();
  const today = new Date(new Date(rest.dataUpdated.date_schedule).setHours(0));
  const inputName = useRef();
  const inputEmail = useRef();
  const [selectedDay, setSelectedDay] = useState(today);
  const [comments, setComments] = useState(rest.dataUpdated.comments);
  const [time, setTime] = useState({
    hourEnd: {
      hour_12: format(
        new Date(rest.dataUpdated.date_schedule).setMinutes(
          new Date(rest.dataUpdated.date_schedule).getMinutes() + rest.dataUpdated.minutes
        ),
        "hh:mm aaaaa'm"
      ),
      hour_24: format(
        new Date(rest.dataUpdated.date_schedule).setMinutes(
          new Date(rest.dataUpdated.date_schedule).getMinutes() + rest.dataUpdated.minutes
        ),
        "HH:mm"
      ),
    },
    hourInit: {
      hour_12: format(new Date(rest.dataUpdated.date_schedule), "hh:mm aaaaa'm"),
      hour_24: format(new Date(rest.dataUpdated.date_schedule), "HH:mm"),
    },
  });

  const [minute, setMinute] = useState(rest.dataUpdated.minutes);
  const [dataGuest, setDataGuest] = useState({ name: "", email: "" });
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [dataForm, setDataForm] = useState({
    date: "",
    hour: "",
    minutes: "",
    title: "",
    guests: [...rest.dataUpdated.guests],
    timeZone: new Date().getTimezoneOffset(),
  });
  const [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
  const firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());
  const days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  });

  function previousMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  function nextMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  function handleOnChangeGuest(e) {
    setDataGuest({ ...dataGuest, [e.target.name]: e.target.value });
  }

  function handleOnChangeForm(e) {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  }

  function addGuets() {
    if (
      isEmail(dataGuest.email) &&
      !dataForm.guests.find((guest) => guest.email === dataGuest.email) &&
      dataForm.guests.length < 9
    ) {
      setDataForm({ ...dataForm, guests: [...dataForm.guests, dataGuest] });
      setDataGuest({});
      inputEmail.current.value = "";
      inputName.current.value = "";
    } else {
      if (!isEmail(dataGuest.email)) {
        setMessageAlert(intl.formatMessage({ id: "alert.errorEmail" }));
        setAlertIsOpen(true);
        setTimeout(() => {
          setAlertIsOpen(false);
        }, 3000);
      }
      if (dataForm.guests.find((guest) => guest.email === dataGuest.email)) {
        setMessageAlert(intl.formatMessage({ id: "alert.emailExists" }));
        setAlertIsOpen(true);
        setTimeout(() => {
          setAlertIsOpen(false);
        }, 3000);
      }
      if (dataForm.guests.length === 9) {
        setMessageAlert(intl.formatMessage({ id: "alert.limitGuests" }));
        setAlertIsOpen(true);
        setTimeout(() => {
          setAlertIsOpen(false);
        }, 3000);
      }
    }
  }

  function removeGuest(email) {
    setDataForm({ ...dataForm, guests: dataForm.guests.filter((guest) => guest.email !== email) });
  }

  function sendToData(body) {
    if (body.title === "" || body.hour === "" || body.minutes === 0 || body.guests === "") {
      setMessageAlert(
        intl.formatMessage({
          id: "inputCalendar.alert",
        })
      );
      setAlertIsOpen(true);
      setTimeout(() => {
        setAlertIsOpen(false);
      }, 3000);
    }
    if (body.title !== "" && body.hour !== "" && body.minutes !== 0 && body.guests) {
      Swal.fire({
        title: intl.formatMessage({ id: "inputCalendar.textUpdated" }),
        html: `<div class="popup-content">
          <div class="head-popup">${format(selectedDay, "EEEE, dd MMMM", {
            locale: locale === "es-MX" ? es : enAU,
          })}</div>
          <div class="head-popup">${body.hour}-${body.minutes} min</div>
        <div class="mt-4"><p><b>${intl.formatMessage({
          id: "inputCalendar.inputTitle",
        })} </b><br><span>${body.title}</span></p></div>
        <div>
          <p>
            <b>${intl.formatMessage({ id: "inputCalendar.inputComments" })}</b>
            <br />
            ${body.comments}
          </p>
        </div>
        <div><p><b>${intl.formatMessage({
          id: "inputCalendar.inputGuests",
        })}</b><br><span>${body.guests.map((guest) => ` ${guest.name}`)}</span></p></div>
        
        </div>`,
        showDenyButton: true,
        confirmButtonText: intl.formatMessage({ id: "buttons.confirm" }),
        denyButtonText: intl.formatMessage({ id: "buttons.cancel" }),
        denyButtonColor: "red",
        confirmButtonColor: "#1a73e8",
        customClass: "alert-popup",
      }).then(async (response) => {
        if (response.isConfirmed) {
          const request = await putWithTokenAuth0(
            `${process.env.REACT_APP_URL_API_MNG}/schedules/${rest.dataUpdated.id}`,
            body
          );
          if (request.response.status === 200) {
            Swal.fire({
              text: intl.formatMessage({ id: "inputCalendar.textUpdateSucces" }),
              icon: "success",
              timer: 2000,
              customClass: "alert-popup",
              showConfirmButton: false,
            }).then(() => {
              navigate("/");
            });
          }
        }
      });
    }
  }

  function handleClickCreate() {
    const body = {
      date: format(selectedDay, "yyyy-MM-dd"),
      hour: time.hourInit.hour_24,
      minutes: minute,
      comments,
      title: dataForm.title !== "" ? dataForm.title : rest.dataUpdated.title,
      guests: dataForm.guests,
      time: {
        minutes: dataForm.timeZone,
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
    sendToData(body);
  }

  return (
    <div className="pt-12 py-1\.5">
      <div className="mt-4">
        <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
          <div className="md:pr-14 md:pl-14">
            <div className="flex items-center">
              <h2 className="flex-auto font-semibold text-gray-900">
                {format(firstDayCurrentMonth, "MMMM yyyy", {
                  locale: locale === "es-MX" ? es : enAU,
                })}
              </h2>
              <button
                type="button"
                onClick={previousMonth}
                className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Previous month {minute}</span>
                <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
              </button>
              <button
                onClick={nextMonth}
                type="button"
                className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              >
                <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
              </button>
            </div>
            <div className="grid grid-cols-7 mt-10 text-xs leading-6 text-center text-gray-500">
              <div>
                <FormattedMessage id="inputCalendar.Sunday" />
              </div>
              <div>
                <FormattedMessage id="inputCalendar.Monday" />
              </div>
              <div>
                <FormattedMessage id="inputCalendar.Tuesday" />
              </div>
              <div>
                <FormattedMessage id="inputCalendar.Wednesday" />
              </div>
              <div>
                <FormattedMessage id="inputCalendar.Thursday" />
              </div>
              <div>
                <FormattedMessage id="inputCalendar.Friday" />
              </div>
              <div>
                <FormattedMessage id="inputCalendar.Saturday" />
              </div>
            </div>
            <div className="grid grid-cols-7 mt-2 text-sm">
              {days.map((day, dayIdx) => (
                <div
                  key={day.toString()}
                  className={classNames(dayIdx === 0 && colStartClasses[getDay(day)], "py-1.5")}
                >
                  <button
                    type="button"
                    onClick={() =>
                      new Date(new Date().setDate(new Date().getDate() - 1)).getTime() <=
                        new Date(new Date(firstDayCurrentMonth).setDate(day.getDate())).getTime() &&
                      setSelectedDay(day)
                    }
                    className={classNames(
                      isEqual(day, selectedDay) && "text-white",
                      !isEqual(day, selectedDay) && isToday(day) && "text-red-500",
                      !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        isSameMonth(day, firstDayCurrentMonth) &&
                        "text-gray-900",
                      !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        !isSameMonth(day, firstDayCurrentMonth) &&
                        "text-gray-400",
                      isEqual(day, selectedDay) && isToday(day) && "bg-button",
                      isEqual(day, selectedDay) && !isToday(day) && "bg-button",
                      !isEqual(day, selectedDay) && "hover:bg-gray-200",
                      (isEqual(day, selectedDay) || isToday(day)) && "font-semibold",
                      "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
                    )}
                  >
                    <time
                      className={
                        new Date(new Date().setDate(new Date().getDate() - 1)).getTime() <=
                        new Date(new Date(firstDayCurrentMonth).setDate(day.getDate())).getTime()
                          ? "dateC"
                          : "text-gray-400"
                      }
                      dateTime={format(day, "yyyy-MM-dd")}
                    >
                      {format(day, "d")}
                    </time>
                  </button>
                </div>
              ))}
            </div>
          </div>
          <section className="md:mt-0 md:pl-14 md:pr-14">
            <div className="width-100 ">
              <p className="p-label">
                <FormattedMessage id="inputCalendar.inputTitle" />{" "}
              </p>
              <input
                type="text"
                className="border rounded width-100"
                name="title"
                defaultValue={rest.dataUpdated.title}
                onChange={(e) => handleOnChangeForm(e)}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <div className="width-100">
                <p className="p-label">
                  <FormattedMessage id="inputCalendar.inputComments" />{" "}
                </p>
                <textarea
                  type="text"
                  className="border rounded width-100"
                  name="title"
                  onChange={(e) => setComments(e.target.value)}
                  defaultValue={comments}
                />
              </div>
            </div>
            <h2 className="font-semibold text-gray-900 mt-4 font-zise-1-5">
              <FormattedMessage id="meeting.ScheduleDay" /> <br />
              <div className="flex">
                <time dateTime={format(selectedDay, "yyyy-MM-dd")}>
                  {format(selectedDay, "EEEE, dd MMMM", {
                    locale: locale === "es-MX" ? es : enAU,
                  })}
                </time>
                <DropdownHours
                  timeInit12={time.hourInit.hour_12}
                  timeInit24={time.hourInit.hour_24}
                  timeEnd12={time.hourEnd.hour_12}
                  selectedDay={selectedDay}
                  setTime={setTime}
                  setMinute={setMinute}
                />
              </div>
            </h2>
            <div className="mt-4">
              <div className="mt-4 width-100">
                <p className="p-label">
                  <FormattedMessage id="inputCalendar.inputGuests" />{" "}
                </p>
                <div className="box-guest">
                  {dataForm.guests.length > 0 &&
                    dataForm.guests.map((guest) => (
                      <div key={guest.email} className="data-guest">
                        <p>{guest.name}</p>
                        <p>{guest.email}</p>
                        <button type="submit" onClick={() => removeGuest(guest.email)}>
                          X
                        </button>
                      </div>
                    ))}
                </div>
                <div className="box-data-guest">
                  <p className="text-data-guest">
                    <FormattedMessage id="labels.name" />
                  </p>
                  <input
                    ref={inputName}
                    type="text"
                    className="border rounded width-100"
                    name="name"
                    onChange={(e) => handleOnChangeGuest(e)}
                  />
                  <p className="text-data-guest">
                    <FormattedMessage id="labels.email" />
                  </p>
                  <input
                    ref={inputEmail}
                    type="email"
                    className="border rounded width-100"
                    name="email"
                    onChange={(e) => handleOnChangeGuest(e)}
                  />
                  <button type="submit" className="rounded button btn-add" onClick={addGuets}>
                    +
                  </button>
                </div>
              </div>
              {alertIsOpen && <div className="mt-4 border rounded bred">{messageAlert}</div>}
              <div className="mt-4 flex-justify-end">
                <button
                  type="button"
                  className="rounded button btn-save"
                  onClick={() => handleClickCreate()}
                >
                  <FormattedMessage id="buttons.save" />
                </button>
                <Link type="button" className="rounded button mleft-15 btn-danger" to="/">
                  <FormattedMessage id="buttons.cancel" />
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default UpdateCalendar;
