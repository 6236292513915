import React, { useState, useMemo } from "react";
import MensajesIngles from "context/Lang/En-Us.json";
import MensajesEspañol from "context/Lang/Es-Mx.json";
import { IntlProvider } from "react-intl";

const langContext = React.createContext();

function LangProvider({ ...rest }) {
  let localePorDefecto = "es-MX";
  let mensajesPorDefecto;
  const lang = localStorage.getItem("lang");

  if (lang) {
    localePorDefecto = lang;
    if (lang === "es-MX") {
      mensajesPorDefecto = MensajesEspañol;
    } else if (lang === "en-US") {
      mensajesPorDefecto = MensajesIngles;
    } else {
      localePorDefecto = "en-US";
      mensajesPorDefecto = MensajesIngles;
    }
  }

  const [mensajes, establecerMensajes] = useState(mensajesPorDefecto);
  const [locale, establecerLocale] = useState(localePorDefecto);

  const establecerLenguaje = (lenguaje) => {
    switch (lenguaje) {
      case "es-MX":
        establecerMensajes(MensajesEspañol);
        establecerLocale("es-MX");
        localStorage.setItem("lang", "es-MX");
        break;
      case "en-US":
        establecerMensajes(MensajesIngles);
        establecerLocale("en-US");
        localStorage.setItem("lang", "en-US");
        break;
      default:
        establecerMensajes(MensajesIngles);
        establecerLocale("en-US");
        localStorage.setItem("lang", "en-US");
    }
  };
  const valueLang = useMemo(() => ({ establecerLenguaje, locale }), [locale]);

  return (
    <langContext.Provider value={valueLang}>
      <IntlProvider locale={locale || "es-MX"} messages={mensajes || MensajesEspañol}>
        {rest.children}
      </IntlProvider>
    </langContext.Provider>
  );
}

export { LangProvider, langContext };
