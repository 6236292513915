/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import { Auth0Provider } from "@auth0/auth0-react";
import { LangProvider } from "context/Lang";
import ContextUser from "context/ContextUser";

ReactDOM.render(
  <Auth0Provider
    domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
    clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
    redirectUri={window.location.origin}
    audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
    scope="read:current_user update:current_user_metadata"
  >
    <BrowserRouter>
      <LangProvider>
        <ContextUser>
          <MaterialUIControllerProvider>
            <App />
          </MaterialUIControllerProvider>
        </ContextUser>
      </LangProvider>
    </BrowserRouter>
  </Auth0Provider>,
  document.getElementById("root")
);
