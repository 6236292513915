import Calendar from "layouts/applications/calendar";

// @mui icons
import Icon from "@mui/material/Icon";
import ViewCreateSchedule from "Views/ViewCreateSchedule";
import RoutePrivate from "components/RoutePrivate/RoutePrivate";
import ViewUpdatedSchedule from "Views/ViewUpdatedSchedule";
import ViewCreateWebinar from "Views/ViewCreateWebinar";

const routes = [
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Calendar",
        key: "calendar",
        route: "/",
        component: <RoutePrivate Component={Calendar} />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Crear",
        key: "createSchedule",
        route: "/create-schedule",
        component: <RoutePrivate Component={ViewCreateSchedule} />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Actualizar",
        key: "updateschedule",
        route: "/update-schedule/:id",
        component: <RoutePrivate Component={ViewUpdatedSchedule} />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Crear Webinar",
        key: "createwebinar",
        route: "/create-webinar",
        component: <RoutePrivate Component={ViewCreateWebinar} />,
      },
    ],
  },
];

export default routes;
