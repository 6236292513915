const Hours = [
  {
    hour_12: "12:00 am",
    hour_24: "00:00",
  },
  {
    hour_12: "12:30 am",
    hour_24: "00:30",
  },
  { hour_12: "01:00 am", hour_24: "01:00" },
  { hour_12: "01:30 am", hour_24: "01:30" },
  { hour_12: "02:00 am", hour_24: "02:00" },
  { hour_12: "02:30 am", hour_24: "02:30" },
  { hour_12: "03:00 am", hour_24: "03:00" },
  { hour_12: "03:30 am", hour_24: "03:30" },
  { hour_12: "04:00 am", hour_24: "04:00" },
  { hour_12: "04:30 am", hour_24: "04:30" },
  { hour_12: "05:00 am", hour_24: "05:00" },
  { hour_12: "05:30 am", hour_24: "05:30" },
  { hour_12: "06:00 am", hour_24: "06:00" },
  { hour_12: "06:30 am", hour_24: "06:30" },
  { hour_12: "07:00 am", hour_24: "07:00" },
  { hour_12: "07:30 am", hour_24: "07:30" },
  { hour_12: "08:00 am", hour_24: "08:00" },
  { hour_12: "08:30 am", hour_24: "08:30" },
  { hour_12: "09:00 am", hour_24: "09:00" },
  { hour_12: "09:30 am", hour_24: "09:30" },
  { hour_12: "10:00 am", hour_24: "10:00" },
  { hour_12: "10:30 am", hour_24: "10:30" },
  { hour_12: "11:00 am", hour_24: "11:00" },
  { hour_12: "11:30 am", hour_24: "11:30" },
  { hour_12: "12:00 pm", hour_24: "12:00" },
  { hour_12: "12:30 pm", hour_24: "12:30" },
  { hour_12: "01:00 pm", hour_24: "13:00" },
  { hour_12: "01:30 pm", hour_24: "13:30" },
  { hour_12: "02:00 pm", hour_24: "14:00" },
  { hour_12: "02:30 pm", hour_24: "14:30" },
  { hour_12: "03:00 pm", hour_24: "15:00" },
  { hour_12: "03:30 pm", hour_24: "15:30" },
  { hour_12: "04:00 pm", hour_24: "16:00" },
  { hour_12: "04:30 pm", hour_24: "16:30" },
  { hour_12: "05:00 pm", hour_24: "17:00" },
  { hour_12: "05:30 pm", hour_24: "17:30" },
  { hour_12: "06:00 pm", hour_24: "18:00" },
  { hour_12: "06:30 pm", hour_24: "18:30" },
  { hour_12: "07:00 pm", hour_24: "19:00" },
  { hour_12: "07:30 pm", hour_24: "19:30" },
  { hour_12: "08:00 pm", hour_24: "20:00" },
  { hour_12: "08:30 pm", hour_24: "20:30" },
  { hour_12: "09:00 pm", hour_24: "21:00" },
  { hour_12: "09:30 pm", hour_24: "21:30" },
  { hour_12: "10:00 pm", hour_24: "22:00" },
  { hour_12: "10:30 pm", hour_24: "22:30" },
  { hour_12: "11:00 pm", hour_24: "23:00" },
  { hour_12: "11:30 pm", hour_24: "23:30" },
];
export default Hours;
