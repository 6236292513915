import { useAuth0 } from "@auth0/auth0-react";

function isKeyExists(obj, key) {
  if (obj[key] === undefined) {
    return false;
  }
  return true;
}

function useRequests() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const postWithTokenCognito = async () => {
    const options = {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    if (!isKeyExists(options.headers, "Origin") && process.env.REACT_APP_LOCAL === "false") {
      options.headers.Referer = `${process.env.REACT_APP_REFERER}`;
      options.headers.Origin = `${process.env.REACT_APP_ORIGIN}`;
    }

    const response = await fetch(
      `https://${process.env.REACT_APP_COGNITO_URL}?client_id=${process.env.REACT_APP_COGNITO_CLIENT}&client_secret=${process.env.REACT_APP_COGNITO_SECRET}&grant_type=client_credentials&client_name=${process.env.REACT_APP_AUTH0_DOMAIN}`,
      options
    );
    const result = await response.json();

    return result;
  };

  const getToken = () => {
    if (isAuthenticated)
      return (async () => {
        try {
          const tokenCognito = await postWithTokenCognito();
          const token = await getAccessTokenSilently({
            audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
            scope: "read:current_user",
          });
          return { tokenCognito, token };
        } catch (error) {
          console.error(error);
          return null;
        }
      })();

    return null;
  };

  const getTokenPublic = async () => {
    const body = {
      client_id: `${process.env.REACT_APP_TOKEN_CLIENT_ID}`,
      client_secret: `${process.env.REACT_APP_TOKEN_SECRET}`,
      audience: `${process.env.REACT_APP_TOKEN_AUDIENCE}`,
      grant_type: "client_credentials",
    };

    const options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(`${process.env.REACT_APP_TOKEN_URL}`, options);
    const result = await response.json();

    return { response, result };
  };

  const getWithToken = async (url) => {
    const token = await getTokenPublic();
    const headers = {
      headers: { Authorization: `Bearer ${token.result.access_token}` },
    };

    const response = await fetch(url, headers);
    const result = await response.json();
    return { response, result };
  };

  const getWithTokenAuth0 = async (url) => {
    const token = await getToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token.tokenCognito.access_token}`,
        usertoken: `${token.token}`,
      },
    };

    if (!isKeyExists(headers.headers, "Origin") && process.env.REACT_APP_LOCAL === "false") {
      headers.headers.Referer = `${process.env.REACT_APP_REFERER}`;
      headers.headers.Origin = `${process.env.REACT_APP_ORIGIN}`;
    }

    const response = await fetch(url, headers);
    const result = await response.json();
    return { response, result };
  };

  const postWithTokenAuth0 = async (url, body) => {
    const token = await getToken();
    const options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token.tokenCognito.access_token}`,
        usertoken: `${token.token}`,
        "Content-Type": "application/json",
      },
    };

    if (!isKeyExists(options.headers, "Origin") && process.env.REACT_APP_LOCAL === "false") {
      options.headers.Referer = `${process.env.REACT_APP_REFERER}`;
      options.headers.Origin = `${process.env.REACT_APP_ORIGIN}`;
    }

    const response = await fetch(url, options);
    const result = await response.json();

    return { response, result };
  };

  const postWithToken = async (url, body) => {
    const options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (!isKeyExists(options.headers, "Origin") && process.env.REACT_APP_LOCAL === "false") {
      options.headers.Referer = `${process.env.REACT_APP_REFERER}`;
    }

    const response = await fetch(url, options);
    const result = await response.json();

    return { response, result };
  };

  const putWithTokenAuth0 = async (url, body) => {
    const token = await getToken();
    const options = {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token.tokenCognito.access_token}`,
        usertoken: `${token.token}`,
        "Content-Type": "application/json",
      },
    };

    if (!isKeyExists(options.headers, "Origin") && process.env.REACT_APP_LOCAL === "false") {
      options.headers.Origin = `${process.env.REACT_APP_ORIGIN}`;
    }

    const response = await fetch(url, options);
    const result = await response.json();

    return { response, result };
  };

  const deleteWithTokenAuth0 = async (url) => {
    const token = await getToken();
    const options = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token.tokenCognito}`,
        usertoken: `${token.token}`,
      },
    };

    if (!isKeyExists(options.headers, "Origin") && process.env.REACT_APP_LOCAL === "false") {
      options.headers.Referer = `${process.env.REACT_APP_REFERER}`;
      options.headers.Origin = `${process.env.REACT_APP_ORIGIN}`;
    }

    const response = await fetch(url, options);
    return { response };
  };

  return {
    getWithTokenAuth0,
    postWithTokenAuth0,
    putWithTokenAuth0,
    deleteWithTokenAuth0,
    getWithToken,
    postWithToken,
  };
}

export default useRequests;
