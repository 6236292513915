import useRequests from "Hooks/useRequests";
import Loader from "components/Loader";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import { format, startOfToday } from "date-fns";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import EventCalendar from "examples/Calendar";
import NextEvents from "..";

function ViewCalendar() {
  const { getWithTokenAuth0, deleteWithTokenAuth0 } = useRequests();
  const today = startOfToday();
  const currentDay = format(today, "yyyy-MM-dd");
  const [selectedDay, setSelectedDay] = useState(today);
  const [dataEvents, setDataEvents] = useState(false);

  const intl = useIntl();

  function colorItem(dateInit, minutes) {
    if (
      new Date().getTime() >= new Date(dateInit).getTime() &&
      new Date().getTime() >= new Date(new Date(dateInit).setMinutes(minutes)).getTime()
    ) {
      return "light";
    }
    if (
      new Date().getTime() <= new Date(dateInit).getTime() &&
      new Date().getTime() <= new Date(new Date(dateInit).setMinutes(minutes)).getTime()
    ) {
      return "custom";
    }
    if (
      new Date().getTime() >= new Date(dateInit).getTime() &&
      new Date().getTime() <= new Date(new Date(dateInit).setMinutes(minutes)).getTime()
    ) {
      return "custom-success";
    }
    return "light";
  }

  const getDataSchedules = async () => {
    const getData = await getWithTokenAuth0(`${process.env.REACT_APP_URL_API_MNG}/schedules`);
    const data = getData?.result?.map((schedule) => {
      const dateInit = schedule.date.replace("Z", "");
      const name = schedule.title;
      const updatedSchedule = { ...schedule, title: `${schedule.title}` };
      const start = format(new Date(dateInit), "yyyy-MM-dd HH:mm:ss");
      const end = format(
        new Date(dateInit).setMinutes(new Date(dateInit).getMinutes() + schedule.minutes),
        "yyyy-MM-dd HH:mm:ss"
      );
      const className = colorItem(dateInit, schedule.minutes);
      const startDatetime = start;
      const endDatetime = end;
      const s = { ...updatedSchedule, start, end, startDatetime, endDatetime, name, className };
      return s;
    });
    setDataEvents([...data]);
  };

  const handleDelete = async (idSchedule) => {
    Swal.fire({
      title: "Alerta!",
      text: "¿Estas seguro que desear eliminar la agenda?",
      icon: "question",
      showDenyButton: true,
      confirmButtonText: intl.formatMessage({ id: "buttons.confirm" }),
      denyButtonText: "Cancelar",
      denyButtonColor: "red",
      confirmButtonColor: "#1a73e8",
      customClass: "alert-popup",
    }).then(async (response) => {
      if (response.isConfirmed) {
        const result = await deleteWithTokenAuth0(
          `${process.env.REACT_APP_URL_API_MNG}/schedules/${idSchedule}`
        );
        if (result.response.status === 200) {
          Swal.fire({
            title: "Se elimino correctamente",
            timer: 2000,
            icon: "success",
            showConfirmButton: false,
          }).then(() => getDataSchedules());
        }
      }
    });
  };

  useEffect(() => {
    getDataSchedules();
  }, []);

  const currentD = new Date().getTime();
  const selectedDayMeetings =
    dataEvents &&
    dataEvents.filter((schedule) => new Date(schedule.end).getTime() >= currentD && schedule);

  localStorage.setItem("schedules", JSON.stringify(selectedDayMeetings));

  return (
    <div>
      {dataEvents ? (
        <MDBox pt={4} className="container">
          <Grid container spacing={3}>
            <Grid item xs={3} xl={9} sx={{ height: "max-content" }}>
              <EventCalendar
                setSelectedDay={setSelectedDay}
                initialView="dayGridMonth"
                initialDate={currentDay}
                events={dataEvents}
                selectable
                editable
              />
            </Grid>

            <Grid item xs={12} xl={3}>
              <MDBox mb={3}>
                <NextEvents
                  meetings={selectedDayMeetings}
                  selectedDay={selectedDay}
                  handleDelete={handleDelete}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default ViewCalendar;
