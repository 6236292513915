import React, { useState } from "react";
import "./style.css";
import { FormattedMessage } from "react-intl";
import { useAuth0 } from "@auth0/auth0-react";
import useRequests from "Hooks/useRequests";

function RegisterName() {
  const { postWithTokenAuth0 } = useRequests();
  const { isAuthenticated } = useAuth0();
  const [name, setName] = useState({ name: "" });
  const [checked, setChecked] = useState(false);

  function handleOnChange(e) {
    setName({ ...name, [e.target.name]: e.target.value });
  }

  function handleSutmit() {
    if (isAuthenticated && checked)
      (async () => {
        const body = { name: name.name, tyc: true };
        const getData = await postWithTokenAuth0(`${process.env.REACT_APP_URL_API_MNG}/user`, body);
        if (getData.response.status === 200) window.location.href = "";
      })();
  }

  return (
    <div className="bg-white shadow rounded card-login">
      <h2 className="m-4">
        <FormattedMessage id="register.name" />
      </h2>
      <div>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
          name="name"
          value={name.name}
          type="text"
          onChange={handleOnChange}
        />
      </div>
      <div className="flex terminos">
        <input
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          name="checked"
          value={checked}
          type="checkbox"
          onChange={(e) => setChecked(e.target.checked)}
        />
        <a href="https://www.virtuales.io/privacy.html" className="mr-1">
          <FormattedMessage id="register.termAndpolicy" />
        </a>
      </div>
      <div>
        <button
          className={`text-white font-bold py-2 px-4 w-full rounded btn-register ${
            checked && "active"
          }`}
          type="button"
          onClick={handleSutmit}
        >
          <FormattedMessage id="register.btn-next" />
        </button>
      </div>
    </div>
  );
}

export default RegisterName;
