import React from "react";
import { FormattedMessage } from "react-intl";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-default">
      <div className="container flex-footer-default-2">
        <div className="flex-footer-default">
          <a href="https://www.virtuales.io" rel="noopener noreferrer" target="_blank">
            <img
              src={`${process.env.REACT_APP_CONTACT_IMG}/logo-virtual-es.svg`}
              className="logo-footer"
              alt="logo"
            />
          </a>
          <div>
            <p className="formtextfooter-default">
              <FormattedMessage id="Copyright" />
              <a
                href="https://www.virtuales.io/privacy.html"
                target="_blank"
                className="subtilte"
                style={{ color: "#00b4cf" }}
                rel="noopener noreferrer"
              >
                {" "}
                <FormattedMessage id="Politicas" />
              </a>
            </p>
          </div>
        </div>
        <div>{`${process.env.REACT_APP_VERSION}`}</div>
      </div>
    </div>
  );
}

export default Footer;
