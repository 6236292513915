/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { Meeting } from "components/InputCalendar";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

function NextEvents({ ...rest }) {
  const [indexPagination, setIndexPagination] = useState(6);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState(false);
  const [items, setItems] = useState([]);

  function handlePagination(type) {
    if (type === "next" && indexPagination < rest.meetings.length) {
      setCurrentPage(indexPagination);
      setIndexPagination(indexPagination + 6);
      setItems([...data].splice(indexPagination, 6));
    }
    if (type === "prev" && currentPage > 0) {
      setCurrentPage(currentPage - 6);
      setIndexPagination(indexPagination - 6);
      setItems([...data].splice(currentPage - 6, indexPagination - 6));
    }
  }
  useEffect(() => {
    setData(rest.meetings.length > 0 ? [...rest.meetings] : []);
    setItems(rest.meetings.length > 0 ? [...rest.meetings].splice(0, 6) : []);
  }, [rest.meetings]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          <div className="flex">
            <FormattedMessage id="meeting.nextEvent" />

            <div className="flex">
              <button
                type="button"
                onClick={() => handlePagination("prev")}
                className="btn-prev mleft-15"
              >
                <span className="fc-icon fc-icon-chevron-left" />
              </button>
              <button type="button" onClick={() => handlePagination("next")} className="btn-next">
                <span className="fc-icon fc-icon-chevron-right" />
              </button>
            </div>
          </div>
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        {items && items.length > 0 ? (
          items.map((meeting) => (
            <Meeting
              meeting={meeting}
              key={meeting.id}
              handleDelete={rest.handleDelete}
              setSelected={rest.setSelected}
              setDataUpdated={rest.setDataUpdated}
            />
          ))
        ) : (
          <p>
            <FormattedMessage id="meeting.NoSchedule" />
          </p>
        )}
      </MDBox>
    </Card>
  );
}

export default NextEvents;
